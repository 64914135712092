@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

* {
  font-family: 'Poppins', sans-serif;
  color: "#333333";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white-color: #fff;
  --primary-blue: #628DFB;
  --primary-red: #FB608C;
  --grey-color: #707070;
  --grey-color2: #7D7D7D;
  --bg-grey: #F7F7FA;
  --body-color: #F7F7FA;
  --text-color: #333333;
  --border-color: #8DADFC;

  /* transition */
  --tran-03: all .2s ease;
  --tran-04: all .3s ease;
  --tran-05: all .4s ease;
  --tran-06: all .5s ease;
}

/* transition */


body {
  background-color: var(--body-color) !important;
}

/* sidebar */
.menu-bar .menu {
  margin-top: 25px;
}

.sidebar header {
  position: relative;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 10px 14px;
  background-color: var(--white-color);
  transition: var(--tran-05);
  z-index: 100;
}

.sidebar.close {
  width: 98px;
}

.sidebar .txt {
  font-weight: 500;
  transition: var(--tran-03);
  white-space: nowrap;
  opacity: 1;
}

.sidebar.close .txt {
  display: none;
}

.sidebar header .toggle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  font-size: 22px;
  height: 15px;
  width: 15px;
  padding: 2%;
  background: var(--primary-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--white-color);
  transition: var(--tran-03);
}

.sidebar.close header .toggle {
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
}

.sidebar.close .menu-links .nav-link a,
.bottom-content .nav-link a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar.close .bottom-content .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-links {
  padding-left: 0;
}

.menu-links .nav-link,
.bottom-content .nav-link {
  height: 50px;
  list-style: none;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.nav-link .nav-icon {
  font-size: 20px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-links .nav-link .nav-icon,
.menu-links .nav-link span {
  color: var(--grey-color);
  transition: var(--tran-03);
}

.bottom-content .nav-link .nav-icon,
.bottom-content .nav-link span {
  color: var(--body-color);
  font-weight: 500;
  letter-spacing: .3px;
  transition: var(--tran-03);
}

.bottom-content .nav-link {
  background: var(--primary-blue);
  border-radius: 10px;
}

.bottom-content .nav-link a {

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: var(--tran-04);
  border-radius: 10px;
}



.menu-links .nav-link a {
  text-decoration: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  transition: var(--tran-04);
  border-radius: 10px;
}

.menu-links .nav-link a:hover {
  background: var(--body-color);
}

.menu-links .nav-link a:hover .nav-icon,
.menu-links .nav-link a:hover .nav-text {
  color: var(--primary-blue);
}

.menu-links .nav-link a:focus .nav-icon,
.menu-links .nav-link a:focus .nav-text,
.menu-links .nav-link a:active .nav-icon,
.menu-links .nav-link a:active .nav-text {
  color: var(--primary-blue);
}
.sidebar.close header .img-text  {
  width: 100%;
}
.sidebar.close header .img-text .img-logo img {
  width: 70px;
  transition: var(--tran-04);
}

.img-text .img-logo img {
  width: 100px;
  transition: var(--tran-04);
}

.sidebar .menu-bar {
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* HOME CSS */

.home {
  position: relative;
  left: 250px;
  height: auto;
  width: calc(100% - 250px);
  background: var(--body-color);
  transition: var(--tran-05);
  padding: 3% 2.5% 0;
}

.sidebar.close~.home {
  left: 100px;
  width: calc(100% - 100px);
}

.home-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gym-text>h3 {
  font-size: 22px;
  font-weight: 600;
  color: var(--primary-blue);
}

.gym-text>p {
  color: var(--text-color);
}

.gym-text>p>span {
  color: var(--primary-blue);
  font-weight: 600;
}

.gym-header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.operator-profil {
  display: flex;
  align-items: center;
  gap: 10px;
}

.gym-header-right>.notification>a>.fa-bell {
  font-size: 20px;
  color: var(--text-color);
  transition: var(--tran-04);
}

.gym-header-right>.notification>a>.fa-bell:hover {
  color: var(--primary-blue);
}

.operator-profil>.profil {
  width: 50px;
  height: 50px;
  background: var(--white-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.operator-profil>.profil>img {
  width: auto;
  height: 40px;
}

.operator-profil>p {
  margin-bottom: 0;
}

.operator-name {
  display: flex;
  align-items: center;
}

.operator__link {
  color: var(--text-color);
  font-weight: 500;
}

.operator-dropdown__link {
  text-decoration: none;
}

.btn-primary {
  background: none !important;
  border: none !important;
  color: var(--text-color) !important;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: 0;
}

.home .home-btn {
  padding: 3% 0;
  display: flex;
  gap: 25px;
}

.home-btn .ads-subs {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 100px;
  background: var(--white-color);
  border-radius: 10px;
}

.home-btn .ads-subs>a {
  font-weight: 600;
  text-decoration: none;
  padding: 7% 14%;
  background: var(--bg-grey);
  border-radius: 10px;
  transition: var(--tran-03);
}

.home-btn .attendance-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 100px;
  background: var(--white-color);
  border-radius: 10px;
}

.attendance-number .attendance-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 57px;
  background: var(--bg-grey);
  border-radius: 10px;
}

.attendance-number .attendance-icon>.fa-person-walking {
  color: var(--primary-blue);
  font-size: 25px;
}

.attendance-count {
  padding: 0 2%;
}

.attendance-count>p {
  margin-bottom: 0;
  color: var(--text-color);
}

.attendance-count>span {
  font-size: 22px;
  font-weight: 600;
  color: var(--text-color);
}

.table-bg {
  background: var(--white-color);
  padding: 3%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

/* search */
/* .searchInput {
  height: 40px;
  padding: 0 2%;
  border-radius: 10px;
  border: none;
  background: var(--bg-grey);
}
.searchInput:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: 0;
}
.searchButton {
  height: 40px;
  border-radius: 10px;
  padding: 0 1%;
  border: none;
} */

.search-box>button {
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  background-color: var(--bg-grey);
}

.search-box>button>.fa-magnifying-glass {
  color: var(--primary-blue);
}

.search-box>.hidden {
  display: none;
}

.input-display {
  width: 200px;
  display: inline-block;
}





.navbar-search {
  display: flex;
}

.navbar-search>button {
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  background-color: var(--bg-grey);
}

.navbar-search>button>.fa-magnifying-glass {
  color: var(--primary-blue);
}

.nav_search__toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  position: relative;
  gap: 90px;
}

.form-search_inner>.fa-xmark {
  display: none;
  font-size: 20px;
  margin-left: 3%;
  cursor: pointer;
  transition: .20s ease-in-out;
  color: var(--grey-color);
}

.form-search_inner>.fa-xmark:hover {
  color: var(--primary-blue);
}

.nav_search__toggle>i {
  transition: .20s ease-in-out;
}

.nav_search__toggle>i:hover {
  color: var(--primary-red);
}

.search-submit>i {
  font-size: 20px;
  color: var(--primary-red);
}



.search_form {
  position: absolute;
  /* top: 90%; */
  /* left: 0; */
  right: 40%;
  height: 40px;
  /* padding-left: 15%; */
  z-index: 1;
  background-color: #fff;
  opacity: 0;
  pointer-events: none;
  transform: translate(-100%, -50%);
  transform-origin: left top;
  transition: .3s ease-in-out;
}



.js-active {
  pointer-events: all;
  opacity: 1;
  transform: translate(0, 0);
}


.form-search_inner .search-input {
  display: block;
  border: 1px solid #b6b3ba;
  border-radius: 10px;
  width: 80%;
  height: 37px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  color: #110f14;
  font-size: 16px;
  box-shadow: 0 0 0 1px transparent;
  transition: .15s ease-in-out;
  margin-right: 10px;
}

.search-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: 0;
}

.form-search_inner {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.form-search_inner .search-submit {
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  color: var(--primary-blue);
  background-color: var(--bg-grey);
}

/* seacrh finish */

/* art-search */
.ant-btn-primary {
  background: var(--primary-blue);
}

.table-bg .table-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.departure_count__wrapper>h6 {
  padding: 2% 0 1%;
  color: var(--text-color);
  font-weight: 600
}

.table-filter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}

.f-select {
  display: flex;
  flex-direction: column;
  /* width: 30%; */
  /* margin: 0 2%; */
}

.f-select>label {
  font-size: 14px;
  color: var(--grey-color);
  letter-spacing: .5px;
}

.f-select>select {
  width: 100%;
  font-size: 14px;
  padding: 6px;
  background: var(--bg-grey);
  border-radius: 10px;
  border: 1px solid var(--clr-grey);
  cursor: pointer;
}

.select:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  outline: 0;
}

/* Status Btns */
.status-change {
  padding: 5% 0;
  display: flex;
  gap: 10px;
}
/* Note Css (Add Subs) */

.note,
.add_Subs {
  position: relative;
  left: 250px;
  height: auto;
  width: calc(100% - 250px);
  background: var(--body-color);
  transition: var(--tran-05);
  padding: 7% 2.5% 0;
}

.sidebar.close~.note,
.sidebar.close~.add_Subs {
  left: 100px;
  width: calc(100% - 100px);
}

.section_header {
  display: flex;
  flex-direction: column;
}

.section_header>h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color);
}

.line {
  width: 90px;
  border: 4px solid var(--primary-blue);
  animation: expandLine 1s forwards;
}

@keyframes expandLine {
  0% {
    width: 0;
  }

  100% {
    width: 90px;
  }
}

.note-textarea {
  width: 649px;
  height: 354px;
  background: var(--white-color);
  border-radius: 20px;
  padding: 3% 3%;
}

.note-textarea_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3% 1%
}

.note-input {
  display: flex;
  flex-direction: column;
}

.note-input>label {
  color: var(--grey-color);
  font-weight: 500;
  padding: 0 0 2% 2.5%;
  letter-spacing: .5px;
}

.note-input-textarea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 55px;
}

.text-buttons {
  display: flex;
  gap: 16px;
}

.btn-library>button {
  width: 159px;
  height: 49px;
  border: none;
  border-radius: 10px;
}

.btn-library>.cansel {
  font-weight: 600;
  letter-spacing: .3px;
  color: var(--primary-blue);
  background: var(--bg-grey);
  transition: var(--tran-04);
}

.btn-library>.save {
  font-weight: 600;
  letter-spacing: .3px;
  color: var(--white-color);
  background: var(--primary-blue);
  transition: var(--tran-04);
}

.btn-library>.save:hover,
.btn-library>.cansel:hover {
  box-shadow: rgba(0, 123, 255, 0.25) 0px 2px 8px 0px;
}

/* ADD SUBS */
.add-input_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3% 1%
}

.add-input_body {
  width: 649px;
  height: auto;
  background: var(--white-color);
  border-radius: 20px;
  padding: 3% 3%;
}

.input-group-text {
  border-radius: 7px 0 0 7px !important;
}
.input-mobil_group {
  width: 100%;
}
.form-group>label {
  font-size: 14px;
  color: var(--grey-color);
  padding-bottom: 1%;
}

.add-input_body {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.formBtn {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.add-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-checkbox {
  padding: 3% 0;
  display: flex;
  gap: 45px;
}

.days_remaining {
  display: flex;
  flex-direction: column;
}


#exception_data label {
  font-size: 13px;
  color: var(--grey-color);
  padding-bottom: 1%;
}

#exception_data {
  display: flex;
  gap: 45px;

}

.add_data {
  display: flex;
  gap: 45px;
}

/* Successful Page - Error Page  */
.successful-body,
.error-body {
  position: relative;
  left: 250px;
  height: auto;
  width: calc(100% - 250px);
  background: var(--body-color);
  transition: var(--tran-05);
  padding: 7% 2.5% 0;
}

.sidebar.close~.successful-body,
.sidebar.close~.error-body {
  left: 100px;
  width: calc(100% - 100px);
}

.successful-wrapper>h2,
.error-text>h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  padding: 0 1%;
}

.error-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5%;
}

/* Payment popup */
.popup_payment {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(217, 217, 217, 0.8);
  padding: 20px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.popup_payment .popup_payment-wrapper {
  opacity: 1 !important;
  width: 867px;
  height: 750px;
  border-radius: 20px;
  background: var(--white-color) !important;
  padding: 2% 3%;
}

.popup_payment-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.popup_payment-body .py-header {
  display: flex;
  justify-content: space-between;
}

.py-header .py-header-title>h3 {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
}

.py-header .py-header-title>span {
  display: inline-block;
  width: 90px;
  border: 4px solid var(--primary-blue);
  animation: expan 1s forwards;
}

@keyframes expan {
  0% {
    width: 0;
  }

  100% {
    width: 90px;
  }
}

/* Close Btn */
.closeBtn>button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 1%;
  border-radius: 5px;
  border: none;
  background: var(--bg-grey);
  transition: var(--tran-05);
}

.closeBtn>button>.fa-xmark {
  color: var(--primary-blue);
  font-size: 22px;
}

.closeBtn>button:hover {
  border: 1px solid var(--border-color);
}

.py-table>table {
  display: flex;
  justify-content: space-between;
  border-spacing: 0;
  width: 100%;
  border: 1px solid rgba(217, 217, 217, 0.7);
  border-radius: 15px;
}

.py-table .table thead tr,
.py-table .table tbody tr {
  display: flex;
  flex-direction: column;
}

.py-table .table thead {
  width: 40%;
}

.py-table .table thead th {
  font-weight: 500 !important;
  color: var(--grey-color);
  font-size: 16px;
  padding: 13px;
}

.py-table .table tbody {
  width: 60%;
}

.py-table .table tbody td {
  padding: 13px 13px 13px 40px;
}

.py-table .table thead th:last-child,
.py-table .table tbody td:last-child {
  border: none;
}

.confirm-library {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.confirmBtn {
  background: var(--primary-blue);
  border: none;
  border-radius: 10px;
  padding: 1.5% 3%;
  color: var(--white-color);
  font-weight: 600;
  letter-spacing: .5px;
  transition: var(--tran-04);
}

.confirmBtn:hover {
  box-shadow: rgba(0, 123, 255, 0.25) 0px 2px 8px 0px;
}

/* Subscriptions */
.subscriptions-hero {
  position: relative;
  left: 250px;
  height: auto;
  width: calc(100% - 250px);
  background: var(--body-color);
  transition: var(--tran-05);
  padding: 3% 2.5% 0;
}

.sidebar.close~.subscriptions-hero {
  left: 100px;
  width: calc(100% - 100px);
}

.subs-header {
  padding: 25px 0;
}

.subs-buttons>ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 3%;
  width: 100%;
  height: 80px;
  background: #E5E7F2;
  border-radius: 15px;
}

.subs-button {
  list-style: none;
  height: 50px;
  width: 25%;
}

.subs-button>a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .1%;
  color: var(--grey-color2);
  transition: var(--tran-04);
  border-radius: 15px;
}

.subs-button>a:hover {
  background: var(--white-color);
  color: var(--primary-blue);
}

.subs-button.active a {
  background-color: white;
  color: var(--primary-blue);
}

.subs-date-wrapper {
  display: flex;
  flex-direction: column;
}

.subs-filter_main label {
  font-size: 13px;
  font-weight: 500;
  color: var(--grey-color);
  padding-left: 1%;
}

.subs-filter {
  display: flex;
  margin-top: 2%;
}

.subs-filter_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white-color);
  border-radius: 20px;
  gap: 55px;
  padding: 2%;
}
.user__link {
  color: var(--text-color) !important;
  text-decoration: none;
}
.users-filter {
  width: 20%;
}

.classes-users {
  width: 20%;
}
.activ-subs_table,
.canseled-sub-table {
  padding: 3% 0;
}

/* Filter Btn */
.btn-filter .filter {
  padding: .5% 2%;
  width: 100px;
  height: 40px;
  background: var(--white-color);
  border: 1.5px solid var(--primary-blue);
  border-radius: 13px;
  font-weight: 600;
  letter-spacing: .5%;
  color: var(--primary-blue);
  transition: var(--tran-04);
}

.btn-filter .filter:hover {
  background: var(--primary-blue);
  color: var(--white-color);
}

.subs-filter_btns {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-top: 2%;
}

/* Frozen and return btn */

/* .frozenBtn {
  width: 100%;
  border: 2px solid var(--primary-blue);
  border-radius: 10px;

} */
.frozenBtn>button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--primary-blue);
  border-radius: 10px;
}
.frozenBtn>button>span {
  font-weight: 600;
  letter-spacing: .5px;
  color: var(--primary-blue);
}

/* .returnBtn {
  background: transparent;
  border: 2px solid var(--primary-red);
  border-radius: 10px;
  width: 100%;
} */

.returnBtn>button {
  width: 100%;
  background: var(--primary-red);
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.returnBtn>button>span {
  color: var(--white-color);
  font-weight: 600;
  letter-spacing: .5px;
}

/* User Information popup */


.information-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  padding: 2.5% 0;
  border-bottom: 1px solid #EBEFFE;

}

.information-description>h5 {
  font-size: 16px;
  margin: 0;
  color: var(--primary-blue);
  font-weight: 500;
}

.modal-footer-info {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 5% 0 2%;
}

.info-save {
  font-weight: 600;
  letter-spacing: .3px;
  color: var(--white-color);
  background: var(--primary-blue);
  transition: var(--tran-04);
  border: none;
}

.info-save>span {
  color: var(--white-color);
}

.info-save:hover,
.info-cansel:hover {
  box-shadow: rgba(0, 123, 255, 0.25) 0px 2px 8px 0px;
}

.info-cansel {
  font-weight: 600;
  letter-spacing: .3px;
  color: var(--primary-blue);
  background: var(--bg-grey);
  transition: var(--tran-04);
  border: none;
}

/* package-link */
.package-link {
  color: var(--primary-blue);
}

.reason-popup {
  text-align: end;
  padding: 2% 0;
  width: 70%;
}

/* ok button ant design */
.ant-modal-confirm-btns {
  display: none;
}

/* POPUP Label */
.label-popup {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .3px;
  color: var(--grey-color);
  padding-bottom: .7%;
}

/* qaytarilma popup */
.return-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.return-header {
  padding: 1% 0 2%;
}

.input-amount {
  display: flex;
  flex-direction: column;
}

/* popup button */
.modal-footer-popup {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 3% 0;
}

.modal-footer-popup>button {
  width: 120px;
  height: 35px;
  border-radius: 10px;
  border: none;
}

.popup-cansel {
  color: var(--primary-blue);
  font-weight: 600;
  letter-spacing: .4px;
  background: var(--bg-grey);
  transition: var(--tran-04);
}

.popup-save {
  color: var(--white-color);
  font-weight: 600;
  letter-spacing: .4px;
  background: var(--primary-blue);
  transition: var(--tran-04);
}

.popup-save:hover,
.popup-cansel:hover {
  box-shadow: rgba(0, 123, 255, 0.25) 0px 2px 8px 0px;
}

/* Dondurulma  */
.freeze_date_amount {
  display: flex;
  gap: 15px;
}

.freeze-date,
.freeze-input-amount {
  display: flex;
  flex-direction: column;
}

.freeze-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.freeze-header {
  padding: 2% 0;
}

/* Packages Page */

.packages-hero {
  position: relative;
  left: 250px;
  height: auto;
  width: calc(100% - 250px);
  background: var(--body-color);
  transition: var(--tran-05);
  padding: 3% 2.5% 0;
}

.sidebar.close~.packages-hero {
  left: 100px;
  width: calc(100% - 100px);
}

.packages-link {
  color: var(--text-color);
  font-weight: 500;
  transition: var(--tran-04);
}

.packages-link:hover {
  color: var(--primary-blue);
}


/* .packages-link:hover {
  color: #ED2939;
} */

.packages-main {
  padding: 2% 0;
}

.packages-header {
  padding: 25px 0;
}

/* paket kart */

.discounted-row {
  background-color: #FFECEC;
}

/* Percantage */
.discount-percentage {
  display: flex;
  align-items: center;
}

.discount-percentage>span {
  font-weight: 500;
  color: #ED2939;
}

.discount_package>span {
  color: #ED2939;
  font-size: 13px;
}

/* Class Page */
.class-hero {
  position: relative;
  left: 250px;
  height: auto;
  width: calc(100% - 250px);
  background: var(--body-color);
  transition: var(--tran-05);
  padding: 3% 2.5% 0;
}

.sidebar.close~.class-hero {
  left: 100px;
  width: calc(100% - 100px);
}

.class-header {
  padding: 25px 0;
}

.class-header-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.list-content,
.schedule {
  padding: 2% 0;
}

/* expand row */
.expanded-row-content {
  display: flex;
  gap: 60px;
  padding-left: 5%;
}

.expanded-row-content p {
  font-weight: 600;
  margin-bottom: 0;
}

.expanded-row-content>.participants>span {
  font-weight: 600;
  color: #FB60D9;
  font-size: 20px;
}

.expanded-row-content>.reserve>span {
  font-weight: 600;
  color: #FBCF60;
  font-size: 20px;
}

.expanded-row-content>.status>span {
  font-weight: 600;
  color: #60FB81;
  font-size: 20px;
}

/* Class List Page */
.classlist-hero {
  position: relative;
  left: 250px;
  height: auto;
  width: calc(100% - 250px);
  background: var(--body-color);
  transition: var(--tran-05);
  padding: 3% 2.5% 0;
}

.sidebar.close~.classlist-hero {
  left: 100px;
  width: calc(100% - 100px);
}

.classlist-header {
  padding: 25px 0;
}

.classlist-reserve__wrapper {
  display: flex;
  gap: 30px;
}

.classlist-reserve-bg {
  background-color: var(--white-color);
  width: 50%;
  padding: 3% 2%;
  border-radius: 20px;
}

.classlist-reserve_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.classlist-reserve_main {
  width: 85%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.reserve_users {
  width: 55%;
}

.users-filter-reserve {
  width: 100%;
}

.reserve_amount__input {
  display: flex;
  flex-direction: column;
}

.reserve-btn>button {
  width: 144px;
  height: 40px;
  border: none;
  border-radius: 7px;
  background: var(--primary-blue);
  color: var(--white-color);
  font-weight: 500;
}

.reserve-btn {
  margin-top: 9px;
}

.reserve-number_bg {
  background-color: var(--white-color);
  width: 15%;
  padding: 3% 2%;
  border-radius: 20px;
}

.reserve-number_body>p {
  color: var(--primary-blue);
  font-weight: 600;
  margin-bottom: 0;
}

.reserve-number_body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reserve-number_body>span {
  color: var(--primary-blue);
  font-weight: 600;
  font-size: 35px;
}

/* classlist table */
.classlist-table {
  padding: 3% 0;
}

.classlist_name {
  padding: 2% 0;
}

.classlist_name>h5 {
  color: var(--primary-blue);
}

.classlist_td_name {
  text-decoration: none;
  background: #E1E5F6;
  color: #6781D8;
  font-weight: 500;
  padding: 2%;
  border-radius: 7px;
}

/* Schedule user page */
/* .schedule_box {

  border-radius: 12px;
} */

.schedule_data {
  background: #EBF7F6;
  border-radius: 12px;
  list-style: none;
  margin-bottom: 15px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
}

.schedule_data__link {
  text-decoration: none;
  display: flex;
  align-items: center;

}

.schedule_data>li>.fa-user {
  font-size: 18px;
  color: var(--primary-blue);
  padding-right: 7px;
}

.schedule_data>li>span {
  font-weight: 400;
  padding-left: 5px;
}

/* .schedule-trainer_name {
  padding-left: 5px;
} */
/* User detail Modal */
.info_photo_wrapper {
  display: flex;
  justify-content: center;
}

.info-photo {
  border: 3px solid var(--border-color);
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

.info-photo>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* popup packages history */
.pack-history_btn {
  border: none;
  background: var(--primary-blue);
  color: var(--white-color);
  padding: 2%;
  border-radius: 10px;
  font-weight: 500;
  letter-spacing: .5px;
}

.package-history {
  padding: 3% 0 0;
  overflow-y: auto;
  max-height: 200px;
}

/* scroll */
.package-history::-webkit-scrollbar {
  width: 10px;
}

.package-history::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.package-history::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.package-history>h6 {
  color: var(--text-color);
}

.pack-history-wrapper {
  list-style: none;
  padding-left: 0;
  padding-right: 2%;
}

.pack-history_full {
  display: flex;
  justify-content: space-between;
}

.pack-history__name>p {
  margin-bottom: 0;
  font-weight: 500;
  color: var(--primary-blue);
  font-size: 16px;
}

.pack-history__date>span {
  font-size: 14px;
  color: var(--grey-color);
  padding: 1% 0;
}

.pack-history__price>span {
  color: var(--primary-blue);
  font-weight: 500;
  font-size: 16px;
}

.pack-history-wrapper>li {
  padding: 2% 0;
}


.pack-history_final-amount {
  font-weight: 500;
  color: var(--text-color);
  display: flex;
  align-items: center;
}

.pack-history_final-amount>span {
  padding-left: 2%;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-blue);
}

/* ClassList status colors */
.active-status {
  background-color: #8ED7A7;
  width: 50px;
  height: 30px;
  border-radius: 10px;
}

.passive-status {
  background-color: #FB608C;
}

.frozen-status {
  background-color: yellow;
}

/* Notifications Page */
.notifications-hero {
  position: relative;
  left: 250px;
  height: auto;
  width: calc(100% - 250px);
  background: var(--body-color);
  transition: var(--tran-05);
  padding: 3% 2.5% 0;
}

.sidebar.close~.notifications-hero {
  left: 100px;
  width: calc(100% - 100px);
}

.notification-header {
  padding: 25px 0;
}

.notification-body {
  background-color: var(--white-color);
  border-radius: 15px;
  padding: 2%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.notification-box {
  display: flex;
  align-items: center;
  background: var(--bg-grey);
  gap: 15px;
  padding: 2%;
  border-radius: 10px;
}

.notification-img {
  width: 50px;
  height: 50px;
  background: var(--white-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.notification-img>img {
  width: auto;
  height: 40px;
}

.notification-text>p {
  margin-bottom: 0;
}

/* notofocation animation */
.notification-animation {
  display: flex;
  align-items: center;
}

.notification-animation>h4 {
  padding-left: 10%;
  color: var(--grey-color);
}

/* Change Password page */

.change-password-hero {
  position: relative;
  left: 250px;
  height: auto;
  width: calc(100% - 250px);
  background: var(--body-color);
  transition: var(--tran-05);
  padding: 3% 2.5% 0;
}

.sidebar.close~.change-password-hero {
  left: 100px;
  width: calc(100% - 100px);
}

.change-password-header {
  padding: 25px 0;
}

.change-password_form {
  background: var(--white-color);
  padding: 5% 2% 2%;
  width: 60%;
  border-radius: 15px;
}

.change-password__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 15%;
}

.change-password__btn>span {
  font-weight: 500;
}


/* Login Page */



.login_wrapper {
  display: flex;

}

.login__body {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 50%;
  padding: 5% 4%;
}

.login_text {
  padding: 4% 0 0;
}

.login_text>h3 {
  font-size: 22px;
  line-height: 34px;
  width: 80%;
  color: var(--grey-color);
  font-weight: 500;
  letter-spacing: .5px;
}

.login_form {
  padding: 3% 2%;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-row {
  display: flex;
  justify-content: flex-start;
  /* flex-direction: column !important; */
  flex-wrap: nowrap !important;
  gap: 20px;
}

.login-btn {
  height: 45px;
  padding: 1% 15%;
}

.login-btn>span {
  font-weight: 500;
  letter-spacing: .3px;
}

.login__carousel {
  padding: 4% 0;
  width: 50%;
  background: var(--primary-blue);
}

.carousel-img {
  display: flex;
  justify-content: center;
}

.carousel-box {
  width: 360px;
  height: 360px;
  border-radius: 50%;
  border: 2px solid var(--body-color);
  animation: border-animation 2s infinite alternate;
}

@keyframes border-animation {
  0% {
    border-width: 2px;
  }

  50% {
    border-width: 7px;
  }

  100% {
    border-width: 2px;
  }
}

.carousel-box>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
/* table btns */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-container_active {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* table show

.dataTable-length {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dataTable-length>span {
  padding: 0 3%;
} */



/* selected-options-hover  */

.selected-options-hover {
  display: none;
  position: absolute;
  font-size: 13px;
  background-color: var(--body-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid var(--border-color);
  padding: 8px;
  border-radius: 4px;
  z-index: 1;
}

.user-select:hover .selected-options-hover {
  display: block;
}

/*  */

.red-row {
  background-color: var(--primary-red) !important;
  opacity: .7;
  color: white;
}
.custom-row {
  background-color: white;
  color: black;
}

.custom-row.ant-table-cell-row-hover {
  background-color: var(--primary-red) !important;
}

/* form */
/* Form container */
.login_form {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}
.login_form> .login_form_body {
  width: 100%;
}
/* Form labels */
.login_form label {
  display: block;
  margin-bottom: 5px;
}

/* Form input fields */
.login_form input[type="text"],
.login_form input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

/* Özel OK düğmesi tasarımı */
.swal2-confirm.custom-ok-button {
  background-color: var(--primary-blue); 
  color: #fff; 
  border: none; 
}


.swal2-confirm.custom-ok-button:hover {
  background-color: var(--border-color); 
}

.alert {
  position: absolute;
  z-index: 1000; /* Yüksək bir z-indeks dəyəri */
}
